import React from "react";
import logo from "../../assets/northstar/northstar_logo_alt.png";

const ElevateCard: React.FC = ({ title, children }) => {
  return (
    <div className="bg-white w-full h-full border border-gray-light rounded-md">
      <div className="p-8">
        <div className="py-4 flex flex-col items-center">
          <img src={logo} alt=""></img>
        </div>
        <div className="flex flex-col">
          <div className="flex h-7 mt-7">
            <div className="grow">
              <div className="h-[50%] border-b border-b-gray-light"></div>
            </div>
            <div className="flex items-center">
              <div className="text-gray uppercase text-sm mx-2">{title}</div>
            </div>
            <div className="grow">
              <div className="h-[50%] border-b border-b-gray-light"></div>
            </div>
          </div>
          <div className="flex justify-center mt-3">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ElevateCard;
