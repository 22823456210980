import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";

import NorthstarLogin from "../../components/Login";
import { useAuthContext } from "../../contexts/authContext";
import Spinner from "../../components/Spinner";
import useURLQueryParameter from "../../hooks/queryParamHook";

const LoginPage: React.FC = () => {
  const { whoami: me, isAuthenticating } = useAuthContext();
  const [redirectTo] = useURLQueryParameter("redirect_to");

  useEffect(() => {
    if (me?.username) {
      navigate(redirectTo || "/");
    }
  }, [me?.username]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login Page</title>
      </Helmet>

      <main className="w-screen h-screen bg-off-white flex flex-center">
        <Spinner active={isAuthenticating} />
        {!me && !isAuthenticating && <NorthstarLogin />}
      </main>
    </>
  );
};

export default LoginPage;
