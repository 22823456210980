import React from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const Spinner: React.FC<{ active?: boolean }> = ({ active }) => {
  return active ? (
    <div className="absolute inset-0 bg-off-white bg-opacity-10 flex justify-center items-center z-50">
      <ArrowPathIcon className="w-12 h-12 text-blue animate-spin" />
    </div>
  ) : (
    <></>
  );
};

export default Spinner;
