/**
 * This module contains code related to Okta authentication.
 *
 * Additional documentation:
 * https://developer.okta.com/docs/guides/implement-grant-type/authcodepkce/main/#request-an-authorization-code
 */

import axios from "axios";
import Cookies from "js-cookie";
import { navigate } from "gatsby";

const oktaDomain = process.env.GATSBY_OKTA_DOMAIN || "";
const oktaProtocol = "https";
const oktaClientId = process.env.GATSBY_OKTA_CLIENT_ID || "";
const authEndpoint = "/oauth2/v1/authorize";
const tokenEndpoint = "/oauth2/v1/token";

const redirectURI = `${process.env.GATSBY_APPLICATION_URL}/login/okta`;

const accessTokenCookieName = "northstar-authorization";
const idTokenCookieName = "idToken";
const verifierCookieName = "verifier";
const stateCookieName = "state";
const stateQueryParameterName = stateCookieName;
const codeQueryParameterName = "code";

/**
 * Pads a Base64-encoded string.
 * @param {string} payload: A Base64-encoded string.
 * @returns {string} A padded Base64-encoded string.
 */
function b64pad(payload: string): string {
  const modulus = payload.length % 4;

  if (modulus == 0) return payload;

  return payload + "=".repeat(4 - modulus);
}

/**
 * Decode a Base64-encoded string with potentially incomplete padding.
 * @param {string} payload: A Base64-encoded string.
 * @returns {string} A string.
 */
function b64decode(payload: string): string {
  return atob(b64pad(payload));
}

interface TokenHeader {
  kid: string;
  alg: string;
}

interface Payload {
  sub: string;
  ver: number;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  jti: string;
  auth_time: number;
}

interface AccessToken extends Payload {
  cid: string;
  uid: string;
  scp: Array<string>;
}

interface IdToken extends Payload {
  amr: Array<string>;
  idp: string;
  at_hash: string;
}

interface Token<PayloadType> {
  header: TokenHeader;
  payload: PayloadType;
  signature: string;
  raw: string;
}

/**
 * Unmarshall a Base64-encoded JWT into an object.
 *
 * @param {string} token: The JWT, consisting of a Base64-encoded
 * JSON header, a Base64-encoded JSON payload, and a signature,
 * concatenated with `.`.
 * @returns {Token<T>} An object implementing the Token<T> interface.
 */
function unmarshall<T>(token: string): Token<T> {
  const [header, payload, signature] = token.split(".");

  const parsedHeader: TokenHeader = JSON.parse(b64decode(header));
  const parsedPayload: T = JSON.parse(b64decode(payload));

  return {
    header: parsedHeader,
    payload: parsedPayload,
    signature: signature,
    raw: token,
  };
}

interface Credentials {
  accessToken: Token<AccessToken>;
  // idToken: Token<IdToken>;
}

/**
 * Generate a random hexadecimal string.
 * @param {int64} size: The number of bytes to use.
 * @returns {string} A hexadecimal representation of a sequence of
 *  size random bytes.
 */
function getRandomHexString(size: number): string {
  const container = new Uint32Array(size);

  window.crypto.getRandomValues(container);

  var s = "";

  container.forEach(function (byte) {
    s += ("0" + (byte & 0xff).toString(16)).slice(-2);
  });

  return s;
}

/**
 * Return the SHA256 digest (in bytes) of a string.
 * @param {string} message: The message to hash.
 * @returns {ArrayBuffer} The SHA256 digest of the message.
 */
async function genDigestMessage(message: string): Promise<ArrayBuffer> {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest("SHA-256", data);
  return hash;
}

/**
 * Compute the code challenge for a given code verifier.
 * @param {string} codeVerifier: A code verifier.
 * @returns {string} A Base64URL-encoded code challenge for the
 *  code verifier.
 */
async function genCodeChallenge(codeVerifier: string): Promise<string> {
  const digest = await genDigestMessage(codeVerifier);
  const b64 = btoa(String.fromCharCode(...new Uint8Array(digest)));
  return b64.replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "");
}

interface AuthorizationVariables {
  verifier: string;
  challenge: string;
}

/**
 * Compute parameters used in the PKCE authorization flow.
 * @returns {AuthorizationVariables} A container for a code verifier and
 *  the code challenge for that code verifier
 */
async function genAuthorizationParameters(): Promise<AuthorizationVariables> {
  const codeVerifier = getRandomHexString(64);
  const codeChallenge = await genCodeChallenge(codeVerifier);

  return {
    verifier: codeVerifier,
    challenge: codeChallenge,
  };
}

/**
 * Append key-value pairs of an object to a URL as query parameters.
 * @param {string} url: A URL object.
 * @param {Object} params: An object where each key is the query
 *  parameter name and each value is that query parameter's value.
 * @return {URL} A URL object.
 */
function addQueryParametersForURL(
  url: URL,
  params: { [key: string]: string | Array<string> },
): URL {
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );

  return url;
}

/**
 * Class representing errors thrown by the PKCE authorization
 * flow.
 */
class AuthorizationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

/**
 * Class representing retriable PKCE authorization errors.
 */
class RetriableAuthorizationError extends AuthorizationError {}

/**
 * Class representing non-retriable PKCE authorization errors.
 */
class FatalAuthorizationError extends AuthorizationError {}

/**
 * Class representing an authentication required failure.
 */
class AuthenticationRequiredError extends RetriableAuthorizationError {}

/**
 * Set multiple cookies using the key-value pairs in an object.
 * @param {Object} cookies: A mapping from cookie names to cookie values.
 * @param {Object} attributes: A mapping from cookie attributes to cookie values,
 *  which will be set on ALL cookies created by this call.
 * @return {void}
 */
function setMultipleCookies(
  cookies: { [key: string]: string },
  attributes: { [key: string]: string | number | boolean | Date },
): void {
  Object.keys(cookies).forEach((cookieName) =>
    Cookies.set(cookieName, cookies[cookieName], attributes),
  );
}

/**
 * Request an authorization code from Okta.
 */
const genAuthorizationUrl = async () => {
  const { verifier, challenge } =
    await genAuthorizationParameters(oktaClientId);
  const url = new URL(`${oktaProtocol}://${oktaDomain}${authEndpoint}`);
  const state = window.crypto.randomUUID();

  addQueryParametersForURL(url, {
    client_id: oktaClientId,
    response_type: "code",
    scope: "openid offline_access",
    redirect_uri: redirectURI,
    state: state,
    code_challenge_method: "S256",
    code_challenge: challenge,
  });

  /**
   * These values need to live long enough for the user to
   * authenticate themselves to Okta, but they shouldn't be so
   * long-lived that they could accidentally be reused.
   */
  const expiration = new Date();
  expiration.setSeconds(expiration.getSeconds() + 300);

  setMultipleCookies(
    { verifier: verifier, state: state },
    { secure: true, expires: expiration },
  );

  return url;
};

function getCredentials(): Credentials {
  const accessToken = Cookies.get(accessTokenCookieName);
  //const idToken = Cookies.get(idTokenCookieName);

  if (accessToken == null)
    //|| idToken == null)
    throw new AuthenticationRequiredError("authentication is required");

  const aTok: Token<AccessToken> = unmarshall<AccessToken>(accessToken);
  //const iTok: Token<IdToken> = unmarshall<IdToken>(accessToken);

  const now = new Date();
  const expiration = new Date();
  expiration.setSeconds(Math.max(aTok.payload.exp)); //, iTok.payload.exp));

  if (now >= expiration)
    throw new AuthenticationRequiredError("authentication expired");

  return {
    accessToken: aTok,
    //idToken: iTok,
  };
}

export {
  getCredentials,
  AuthenticationRequiredError,
  RetriableAuthorizationError,
  FatalAuthorizationError,
  genAuthorizationUrl,
};
